import { v4 as uuidv4 } from 'uuid';
import { getDataFromApi } from '../services/api';
import { objectIsEmpty } from '../utils/general';
import { stepsDefaultValues } from './constants';  

// Actions
export const LOAD_STATE_FROM_DB = 'LOAD_STATE_FROM_DB';
export const ADD_BUSINESS = 'ADD_BUSINESS';
export const EDIT_BUSINESS = 'EDIT_BUSINESS';
export const ADD_FARM = 'ADD_FARM';
export const EDIT_FARM = 'EDIT_FARM';
export const ADD_EQUIPMENT = 'ADD_EQUIPMENT';
export const EDIT_EQUIPMENT = 'EDIT_EQUIPMENT';
export const DELETE_EQUIPMENT = 'DELETE_EQUIPMENT';
export const ADD_METER = 'ADD_METER';
export const EDIT_METER = 'EDIT_METER';
export const DELETE_METER = 'DELETE_METER';
export const ADD_ENERGYBILL = 'ADD_ENERGYBILL';
export const EDIT_ENERGYBILL = 'EDIT_ENERGYBILL';
export const DELETE_ENERGYBILL = 'DELETE_ENERGYBILL';
export const EDIT_REPORTING = 'EDIT_REPORTING';
export const EDIT_STEP = 'EDIT_STEP';
export const ADD_FARMPRACTICE = 'ADD_FARMPRACTICE';
export const EDIT_FARMPRACTICE = 'EDIT_FARMPRACTICE';
export const DELETE_FARMPRACTICE = 'DELETE_FARMPRACTICE';

export const businessDefaultValues = ({
  id: "",
  businessName: "",
  businessContactName: "",
  businessContactNumber: "",
});

export const farmDefaultValues = ({
  id: "",
  businessId: "",
  farmName: "",
  farmType: "",
  farmAnnualProduction: "",
  farmAnnualProductionUnits: "",
  farmAnnualProductionUnitsOther: ""
});

export const equipmentDefaultValues = ({
  id: "",
  farmId: "",
  equipmentTypeId: "",  
  fuelTypeId: "",
  description: "",  
  runTimeHours: "",
  runTimeHoursTimeframeUnitsId: "", 
  quantity: "", 
  // Petrol / Gas fields  
  litresPerHour: "",  
  dollarsPerLitre: "",
  // Electricity fields
  meterId: "",
  kWRating: "",  
  percentageCycling: "",
  centsPerKWH: ""
});

export const meterDefaultValues = ({
  id: "",
  number: "",
  description: "",
  isControlledLoad: false
});

export const energyBillDefaultValues = ({
  id: "",
  farmId: "",
  energyBillTypeId: "",
  fuelTypeId: "", 
  meterId: "", 
  days: "",
  usage: "", 
  charge: "", 
  avgUsagePerDay: "",
  avgChargePerDay: ""
});

export const initialAppDataState = {
  data: {
    businesses: [],
    farms: [], 
    equipment: [], 
    energyBills: [],
    reporting: {
      reportTimeframeUnitId: 1,
      reportEnergyUnitId: 1
    }, 
    steps: stepsDefaultValues, 
    farmsPractices: [],
    meters: []
  }
};

export const addOrEditBusiness = (...args) => {
  const [business] = args[0]; 
  
  if (business.id) {
    return {
      type: EDIT_BUSINESS,
      business
    }
  } else {
    business.id = uuidv4(); 
    
    return {
      type: ADD_BUSINESS,
      business
    }
  }
};

export const addOrEditFarm = (...args) => {
  const [businessId, farm] = args[0]; 
    
  if (farm.id) {
    return {
      type: EDIT_FARM,
      farm
    }
  } else {
    farm.id = uuidv4(); 
    farm.businessId = businessId;

    return {
      type: ADD_FARM,
      farm
    }
  }
};

export const addOrEditEquipment = (...args) => {
  const [farmId, equipment] = args[0]; 
    
  if (equipment.id) {
    return {
      type: EDIT_EQUIPMENT,
      equipment
    }
  } else {
    equipment.id = uuidv4(); 
    equipment.farmId = farmId;

    return {
      type: ADD_EQUIPMENT,
      equipment
    }
  }
};

export const deleteEquipment = (...args) => {
  const [ equipmentId ] = args[0]; 
  return {
    type: DELETE_EQUIPMENT,
    equipmentId
  }
}

export const addOrEditMeter = (...args) => {
  const [farmId, meter] = args[0]; 
    
  if (meter.id) {
    return {
      type: EDIT_METER,
      meter
    }
  } else {
    meter.id = uuidv4(); 
    meter.farmId = farmId;

    return {
      type: ADD_METER,
      meter
    }
  }
};

export const deleteMeter = (...args) => {
  const [ meterId ] = args[0]; 
  return {
    type: DELETE_METER,
    meterId
  }
}

export const addOrEditEnergyBill = (...args) => {
  const [farmId, energyBill] = args[0]; 

  if (energyBill.id) {
    return {
      type: EDIT_ENERGYBILL,
      energyBill
    }
  } else {
    energyBill.id = uuidv4(); 
    energyBill.farmId = farmId;

    return {
      type: ADD_ENERGYBILL,
      energyBill
    }
  }
};

export const deleteEnergyBill = (...args) => {
  const [ energyBillId ] = args[0]; 
  return {
    type: DELETE_ENERGYBILL,
    energyBillId
  }
}

export const addFarmPractice = (...args) => {
  const [farmId, practiceId, orderId] = args[0];

  return {
    type: ADD_FARMPRACTICE,
    object: {
      farmId, 
      practiceId,
      orderId
    }
  }
}

export const editFarmPractice = (...args) => {
  const [farmPractice] = args[0];

  return {
    type: EDIT_FARMPRACTICE,
    farmPractice
  }
}

export const deleteFarmPractice = (...args) => {
  const [farmId, practiceId] = args[0];

  return {
    type: DELETE_FARMPRACTICE,
    object: {
      farmId, 
      practiceId
    }
  }
}

export const updateStepValue = (...args) => {
  const [step] = args[0];

  return {
    type: EDIT_STEP,
    step
  }
}

export const reportingParameters = (...args) => {
  const [object] = args[0];
  return {
    type: EDIT_REPORTING, 
    object
  } 
}

export const loadStateFromDb = () => {
  return async (dispatch) => {
    const dbData = await getDataFromApi();
    
    if (dbData && !objectIsEmpty(dbData)) {
      dispatch(loadState(dbData));
    }    
  }
};

const loadState = dbData => ({
  type: LOAD_STATE_FROM_DB,
  dbData
})

// Selectors
export const selectAppData = state => {
  return state.appData.data;
}

export const selectAllBusinesses = state => {
  return state.appData.data.businesses;
}

export const selectBusiness = (state, businessId) => {
  return state.appData.data.businesses?.filter(b => b.id.includes(businessId));
}

export const selectAllBusinessFarms = (state, businessId) => {
  return state.appData.data.farms?.filter(f => f.businessId.includes(businessId));
}

export const selectAllFarms = state => {
  return state.appData.data.farms;
}

export const selectFarm = (state, farmId) => {
  return state.appData.data.farms?.filter(f => f.id.includes(farmId));
}

export const selectAllFarmEquipments = (state, farmId) => {
  return state.appData.data.equipment?.filter(e => e.farmId.includes(farmId));
}

export const selectEquipment = (state, equipmentId) => {
  return state.appData.data.equipment?.filter(e => e.id.includes(equipmentId));
}

export const selectAllFarmMeters = (state, farmId) => {
  return state.appData.data.meters?.filter(e => e.farmId.includes(farmId));
}

export const selectMeter = (state, meterId) => {
  return state.appData.data.meters?.filter(e => e.id.includes(meterId));
}

export const selectAllFarmEnergyBills = (state, farmId) => {
  return state.appData.data.energyBills?.filter(e => e.farmId.includes(farmId));
}

export const selectEnergyBill = (state, energyBillId) => {
  return state.appData.data.energyBills?.filter(e => e.id.includes(energyBillId));
}

export const selectReportingTimeframeUnits = (state) => {
  return state.appData.data.reporting.reportTimeframeUnitId; 
}

export const selectReportingEnergyUnits = (state) => {
  return state.appData.data.reporting.reportEnergyUnitId; 
}

export const selectSteps = (state) => {
  return state.appData.data.steps; 
}

export const selectFarmPractices = (state, farmId) => {
  return state.appData.data.farmsPractices?.filter(fp => fp.farmId.includes(farmId));
} 

// Reducer
export default function appData(state = initialAppDataState, action) {
  // this is crap but apparently necessary
  const farmsArray = [ ...(state.data.farms || []) ];
  const businessesArray = [ ...(state.data.businesses || []) ];
  const equipmentArray = [ ...(state.data.equipment || []) ];
  const energyBillsArray = [ ...(state.data.energyBills || []) ];
  const farmsPracticesArray = [ ...(state.data.farmsPractices || []) ];
  const metersArray = [ ...(state.data.meters || []) ];

  switch (action.type) {
    case LOAD_STATE_FROM_DB:
      return {
        ...state,
        data: action.dbData
      };
    case ADD_BUSINESS:
      return {
        ...state,
        data: {
          businesses: state.data.businesses.concat(action.business),
          farms: farmsArray,
          equipment: equipmentArray,
          energyBills: energyBillsArray,
          reporting: state.data.reporting,
          steps: state.data.steps,
          farmsPractices: farmsPracticesArray,
          meters: metersArray
        }
      };
    case EDIT_BUSINESS:
      return {
        ...state,
        data: {
          businesses: state.data.businesses.map(b => {
            if (b.id === action.business.id) {
              return { ...b, ...action.business }
            }
            return b;
          }),
          farms: farmsArray,
          equipment: equipmentArray,
          energyBills: energyBillsArray,
          reporting: state.data.reporting,
          steps: state.data.steps,
          farmsPractices: farmsPracticesArray,
          meters: metersArray
        }
      };
    case ADD_FARM:
      return {
        ...state,
        data: {
          businesses: businessesArray,
          farms: state.data.farms.concat(action.farm),
          equipment: equipmentArray,
          energyBills: energyBillsArray,
          reporting: state.data.reporting,
          steps: state.data.steps,
          farmsPractices: farmsPracticesArray,
          meters: metersArray
        }
      };
    case EDIT_FARM:
      return {
        ...state,
        data: {
          businesses: businessesArray,
          farms: state.data.farms.map(f => {
            if (f.id === action.farm.id) {
              return { ...f, ...action.farm }
            }
            return f;
          }),
          equipment: equipmentArray,
          energyBills: energyBillsArray,
          reporting: state.data.reporting,
          steps: state.data.steps,
          farmsPractices: farmsPracticesArray,
          meters: metersArray
        }
      };
    case ADD_EQUIPMENT:
      return {
        ...state,
        data: {
          businesses: businessesArray,
          farms: farmsArray,
          equipment: state.data.equipment.concat(action.equipment),
          energyBills: energyBillsArray,
          reporting: state.data.reporting,
          steps: state.data.steps,
          farmsPractices: farmsPracticesArray,
          meters: metersArray
        }
      };
    case EDIT_EQUIPMENT:
      return {
        ...state,
        data: {
          businesses: businessesArray,
          farms: farmsArray,
          equipment: state.data.equipment.map(e => {
            if (e.id === action.equipment.id) {
              return { ...e, ...action.equipment }
            }
            return e;
          }),
          energyBills: energyBillsArray,
          reporting: state.data.reporting,
          steps: state.data.steps,
          farmsPractices: farmsPracticesArray,
          meters: metersArray
        }
      };
    case DELETE_EQUIPMENT:
      console.log('state.data.equipment: ', state.data.equipment); 
      console.log('action.equipment: ', action.equipment);
      return {
        ...state,
        data: {
          businesses: businessesArray,
          farms: farmsArray,          
          equipment: state.data.equipment.filter(e => e.id !== action.equipmentId),
          energyBills: energyBillsArray,
          reporting: state.data.reporting,
          steps: state.data.steps,
          farmsPractices: farmsPracticesArray,
          meters: metersArray
        }
      };
    case ADD_METER:
      return {
        ...state,
        data: {
          businesses: businessesArray,
          farms: farmsArray,
          equipment: equipmentArray,
          energyBills: energyBillsArray,
          reporting: state.data.reporting,
          steps: state.data.steps,
          farmsPractices: farmsPracticesArray,
          meters: state.data.meters.concat(action.meter)
        }
      };
    case EDIT_METER:
      return {
        ...state,
        data: {
          businesses: businessesArray,
          farms: farmsArray,
          equipment: equipmentArray,
          energyBills: energyBillsArray,
          reporting: state.data.reporting,
          steps: state.data.steps,
          farmsPractices: farmsPracticesArray,
          meters: state.data.meters.map(m => {
            if (m.id === action.meter.id) {
              return { ...m, ...action.meter }
            }
            return m;
          }),
        }
      };
    case DELETE_METER:
      return {
        ...state,
        data: {
          businesses: businessesArray,
          farms: farmsArray,
          equipment: equipmentArray,
          energyBills: energyBillsArray,
          reporting: state.data.reporting,
          steps: state.data.steps,
          farmsPractices: farmsPracticesArray,
          meters: state.data.meters.filter(m => m.id !== action.meterId)
        }
      };
    case ADD_ENERGYBILL:
      return {
        ...state,
        data: {
          businesses: businessesArray,
          farms: farmsArray,
          equipment: equipmentArray,
          energyBills: state.data.energyBills.concat(action.energyBill),
          reporting: state.data.reporting,
          steps: state.data.steps,
          farmsPractices: farmsPracticesArray,
          meters: metersArray
        }
      };
    case EDIT_ENERGYBILL:
      return {
        ...state,
        data: {
          businesses: businessesArray,
          farms: farmsArray,
          equipment: equipmentArray,
          energyBills: state.data.energyBills.map(eb => {
            if (eb.id === action.energyBill.id) {
              return { ...eb, ...action.energyBill }
            }
            return eb;
          }),
          reporting: state.data.reporting,
          steps: state.data.steps,
          farmsPractices: farmsPracticesArray,
          meters: metersArray
        }
      };
      case DELETE_ENERGYBILL:
        return {
          ...state,
          data: {
            businesses: businessesArray,
            farms: farmsArray,
            equipment: equipmentArray,
            energyBills: state.data.energyBills.filter(eb => eb.id !== action.energyBillId),
            reporting: state.data.reporting,
            steps: state.data.steps,
            farmsPractices: farmsPracticesArray,
            meters: metersArray
          }
        };
    case EDIT_REPORTING:
      return {
        ...state,
        data: {
          businesses: businessesArray,
          farms: farmsArray,
          equipment: equipmentArray,
          energyBills: energyBillsArray,
          reporting: action.object,
          steps: state.data.steps,
          farmsPractices: farmsPracticesArray,
          meters: metersArray
        }
      }
    case EDIT_STEP:
      return {
        ...state,
        data: {
          businesses: businessesArray,
          farms: farmsArray,
          equipment: equipmentArray,
          energyBills: energyBillsArray,
          reporting: state.data.reporting,
          steps: state.data.steps.map(s => {
            if (s.id === action.step.id) {
              return { ...s, ...action.step }
            }
            return s;
          }),
          farmsPractices: farmsPracticesArray,
          meters: metersArray
        }
      };
    case ADD_FARMPRACTICE:
      return {
        ...state,
        data: {
          businesses: businessesArray,
          farms: farmsArray,
          equipment: equipmentArray,
          energyBills: energyBillsArray,
          reporting: state.data.reporting,
          steps: state.data.steps,
          farmsPractices: state.data.farmsPractices.concat(action.object),
          meters: metersArray
        }
      };
    case EDIT_FARMPRACTICE:
      return {
        ...state,
        data: {
          businesses: businessesArray,
          farms: farmsArray,
          equipment: equipmentArray,
          energyBills: energyBillsArray,
          reporting: state.data.reporting,
          steps: state.data.steps,
          farmsPractices: state.data.farmsPractices.map(fp => {
            if (fp.farmId === action.farmPractice.farmId && fp.practiceId === action.farmPractice.practiceId) {
              return { ...fp, ...action.farmPractice }
            }
            return fp;
          }),
          meters: metersArray
        }
      };
    case DELETE_FARMPRACTICE:
      return {
        ...state,
        data: {
          businesses: businessesArray,
          farms: farmsArray,
          equipment: equipmentArray,
          energyBills: energyBillsArray,
          reporting: state.data.reporting,
          steps: state.data.steps,
          farmsPractices: state.data.farmsPractices.filter(farmPractice => !(farmPractice.farmId === action.object.farmId && farmPractice.practiceId === action.object.practiceId)),
          meters: metersArray
        }
      };

    default:
      console.warn("appData - unknown event: state =", state, "event =", action);
      return state;
  }
}