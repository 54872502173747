const appStateKey = 'appState'; 

export const loadLocalState = () => {
    try {
      const serialState = localStorage.getItem(appStateKey);
      if (serialState === null) {
        return undefined;
      }
      return JSON.parse(serialState);
    } catch (err) {
      return undefined;
    }
};

export const saveLocalState = (state) => {
    try {
      const serialState = JSON.stringify(state);
      localStorage.setItem(appStateKey, serialState);
    } catch(err) {
        console.error('Error in saveLocalState() : ', err);
    }
};
