import React, { Fragment } from 'react';
import clsx from 'clsx';
import { Paper } from '@material-ui/core'; // List, ListItem, ListItemText Grid,
import { connect } from 'react-redux';
import { appName, copyright } from '../../config';
import styled from 'styled-components';
import nswGovLogo from '../../assets/images/logos/nsw__gov_logo.svg';
import tocalLogo from '../../assets/images/logos/tocal_college_logo.jpg';

const Parent = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Child = styled.div`
  flex: 1;
  height: 150px;
`;

const Item = styled.div`  
  height: 150px;
  display: flex; 
  align-items: center;
  justify-content: center;
`;

const Footer = props => {
  const { footerFixed } = props;
  return (
    <Fragment>
      <Paper
        square
        className={clsx('app-footer text-black-50', {
          'app-footer--fixed': footerFixed
        })}>
          <Parent>
            <Child> 
              <Item>
                <img
                  className="nsw-gov-logo-img m-4"
                  alt="NSW Government Logo"
                  src={nswGovLogo}
                />
              </Item>
            </Child>
            <Child>
              <Item>
                <span>{appName} - {copyright}</span>
              </Item>
            </Child>
            <Child>
              <Item>
                <img
                  className="tocal-logo-img m-4"
                  alt="Tocal College Logo"
                  src={tocalLogo}
                />
              </Item>
            </Child>
          </Parent>
      </Paper>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  footerFixed: state.ThemeOptions.footerFixed
});
export default connect(mapStateToProps)(Footer);
