import ThemeOptions from './ThemeOptions';
import { selectAppData } from './appData';
import appData from './appData';
import general from './general';
import { saveLocalState } from '../localStateLoader'; 
import  ApiStateLoader from '../apiStateLoader'; 

const apiStateLoader = new ApiStateLoader();

// special Action to write to API and LocalStorage
export function saveData(dispatchFunction, ...data) {
  return function (dispatch, getState) { // dispatch and getState is injected by thunk
    // first make the desired change
    dispatch(dispatchFunction(data));

    // now save to api/db
    const appData = selectAppData(getState());   
    apiStateLoader.saveState(appData);

    // and to local storage     
    saveLocalState(appData);
  }
}

// eslint-disable-next-line
export default {
  appData,
  general,
  ThemeOptions
};
