export const objectIsEmpty = object => {
  return object && Object.keys(object).length === 0
}

export const getFarmAnnualProductionUnits = (farm) => {
  if (farm.farmAnnualProductionUnits !== 'Please nominate a Unit of Production') {
    return farm.farmAnnualProductionUnits;
  } else {
    return farm.farmAnnualProductionUnitsOther; 
  }
}