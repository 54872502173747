import { getDataFromApi, saveDataToApi } from './services/api';
import { initialAppDataState } from './reducers/appData'; 

export default class ApiStateLoader {
  loadState() {
    try {
      let serializedState = getDataFromApi();

      if (serializedState === null) {
          return initialAppDataState.data;
      }
      return JSON.parse(serializedState);
    }
    catch (err) {
      return initialAppDataState.data;
    }
  }

  saveState(state) {
    try {
      saveDataToApi(state);
    }
    catch (err) {
      console.error('Error saving state in StateLoader.saveState()');
    }
  }
}
