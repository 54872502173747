import React, { Component, Fragment } from 'react';
import { Container, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Notifier } from '@airbrake/browser';

class ErrorBoundary extends Component{
  constructor(props){
      super(props)

      this.state = {
          throwsError: false
      }
      this.airbrake = new Notifier({
        projectId: 457721,
        projectKey: '7b0ca1a0d5231fe59be8fc79dc681bcb'
      });
  }

  static getDerivedStateFromError(error){
      return {
          throwsError: true
      }
  }

  componentDidCatch(error, info){
      console.log('Error: ', error);
      console.log('Info: ', info);

      this.airbrake.notify({
        error: error,
        params: {info: info}
      });
  }

  render(){
    if(this.state.throwsError){
      return (
        <Fragment>
          <Container maxWidth="md" className="pb-5 pt-5">
            <h1>Oops! An error occurred</h1>
            <Button
              to="/Dashboard"
              component={Link}
              size="large"
              color="primary"
              variant="contained"
              className="m-2 py-3 px-5"
              title="Energy Audit System">
              <span className="btn-wrapper--label">Go to Dashboard</span>
            </Button>
          </Container>
        </Fragment>
      )
    }

    return this.props.children;
  }
}

export default ErrorBoundary; 