import { useMsal } from "@azure/msal-react";
import React, { Fragment, useEffect, useState } from 'react';
import {
  Box, Button,
  Divider, List,
  ListItem, Menu
} from '@material-ui/core';

export default function HeaderUserbox() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { instance, accounts } = useMsal();
  const [name, setName] = useState(null);
  const [userName, setUserName] = useState(null);

  useEffect(() => {
    if (accounts.length > 0) {
      setName(accounts[0].name.split(" ")[0]);
      setUserName(accounts[0].username);
    }
  }, [accounts]);

  const handleLogout = () => {
        // setAnchorEl(null);
        instance.logout();
    }

  return (
    <Fragment>
      <Button
        color="inherit"
        onClick={handleClick}
        className="px-3 text-left btn-inverse d-flex align-items-center">
        <div className="d-xl-block pl-3">
          <div className="font-weight-bold pt-2 line-height-1">{name}</div>
          <span className="text-white-50">{userName}</span>
        </div>
        {/* <span className="pl-1 pl-xl-3">
          <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
        </span> */}
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        onClose={handleClose}
        className="ml-2">
        <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
          <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
            <Box>
              {/* <Avatar sizes="44" alt="Emma Taylor" src={avatar5} /> */}
            </Box>
            <div className="pl-3  pr-3">
              <div className="font-weight-bold text-center pt-2 line-height-1">
                {name}
              </div>
              <span className="text-black-50 text-center">
                {userName}
              </span>
            </div>
            <Divider className="w-100 mt-2" />
            {/* <ListItem button disabled>My Account</ListItem>
            <ListItem button disabled>Profile settings</ListItem> */}
            <ListItem button onClick={handleLogout}>Logout</ListItem>
            {/* <Divider className="w-100" /> */}
            {/* <ListItem className="d-block rounded-bottom px-3 pt-3 pb-0 text-center">
              <Tooltip arrow title="Twitter">
                <Button color="default" className="text-twitter">
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={['fab', 'twitter']} />
                  </span>
                </Button>
              </Tooltip>
            </ListItem> */}
          </List>
        </div>
      </Menu>
    </Fragment>
  );
}
