export const SET_HOMEACCOUNT = 'SET_HOME_ACCOUNT';
export const SET_LOGGED_IN = 'SET_LOGGED_IN';
export const SET_LOADED_FROM_DB = 'SET_LOADED_FROM_DB';
export const SET_NETWORK = 'SET_NETWORK';


export const setHomeAccount = homeAccount  => ({
  type: SET_HOMEACCOUNT,
  homeAccount
});

export const setLoggedIn = boolean  => ({
  type: SET_LOGGED_IN,
  boolean
});

export const setLoadedFromDb = boolean  => ({
  type: SET_LOADED_FROM_DB,
  boolean
});

export const setNetwork = network  => ({
  type: SET_NETWORK,
  network
});



const initialState = {
  homeAccount: '',
  loggedIn: false,
  loadedFromDb: false,
  network: {
    loading: false,
    error: false,
    lastError: ''
  }
};

export default function General(state = initialState, action) {
  switch (action.type) {
    case SET_HOMEACCOUNT:
      return {
        ...state,
        homeAccount: action.homeAccount
      }
    case SET_LOGGED_IN:
      return {
        ...state,
        loggedIn: action.boolean
      }
    case SET_LOADED_FROM_DB:
      return {
        ...state,
        loadedFromDb: action.boolean
      }
    case SET_NETWORK:
      return {
        ...state,
        network: action.network
      }
    default:
      console.warn("General - unknown event: state =", state, "event =", action);
      return state;
  }
}