import { ThemeProvider } from '@material-ui/styles';
import { AnimatePresence, motion } from 'framer-motion';
import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { LeftSidebar, PresentationLayout } from './layout-blueprints';
import MuiTheme from './theme';

const Landing = lazy(() => import('./pages/Landing'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Instructions = lazy(() => import('./pages/Instructions'));
const Businesses = lazy(() => import('./pages/subpages/Businesses'));
const Business = lazy(() => import('./pages/subpages/Business'));
const Farms = lazy(() => import('./pages/subpages/Farms'));
const Farm = lazy(() => import('./pages/subpages/Farm'));
const Equipment = lazy(() => import('./pages/subpages/Equipment'));
const Reconciliation = lazy(() => import('./pages/Reconciliation'));
const Report = lazy(() => import('./pages/Report'));
const Opportunities = lazy(() => import('./pages/Opportunities'));
const Prioritisation = lazy(() => import('./pages/Prioritisation'))
const Meter = lazy(() => import('./pages/subpages/Meter')); 
const EnergyBill = lazy(() => import('./pages/subpages/EnergyBill'));

const FinalReport = lazy(() => import('./pages/FinalReport'));

const Stepper = lazy(() => import('./pages/Stepper'));

const Routes = () => {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense
          fallback={
            <div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
              <div className="w-50 mx-auto">
                Please wait while the app loads
              </div>
            </div>
          }>
          <Switch>
            <Redirect exact from="/" to="/landing" />
            <Route path={['/landing']}>
              <PresentationLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path="/Landing" component={Landing} />
                  </motion.div>
                </Switch>
              </PresentationLayout>
            </Route>
            <Route
              path={[
                '/dashboard',
                '/instructions',
                '/businesses',
                '/businesses/new',
                '/businesses/edit/:businessId?',
                '/businesses/:businessId?/farms',
                '/businesses/:businessId?/farms/new',
                '/businesses/:businessId?/farm/edit/:farmId?',
                '/businesses/:businessId?/farms/:farmId?/equipment/new',
                '/businesses/:businessId?/farms/:farmId?/equipment/edit/:equipmentId?',
                '/businesses/:businessId?/farms/:farmId?/energybill/new',
                '/businesses/:businessId?/farms/:farmId?/energybill/edit/:energyBillId?',
                '/reconciliation',
                '/reports/dashboard',
                '/opportunities',
                '/prioritisation',  
                '/finalreport',  
                '/stepper'
              ]}>
              <LeftSidebar>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route
                      path="/dashboard"
                      component={Dashboard}
                    />
                    {
                    <>
                      <Route path="/instructions" exact component={Instructions} />
                      <Route path="/businesses" exact component={Businesses} />
                      <Route path="/businesses/new" exact component={Business} />
                      <Route path="/businesses/edit/:businessId?" exact component={Business} />
                      <Route path="/businesses/:businessId?/farms" exact component={Farms} />
                      <Route path="/businesses/:businessId?/farms/new" exact component={Farm} />
                      <Route path="/businesses/:businessId?/farms/edit/:farmId?" exact component={Farm} />
                      <Route path="/businesses/:businessId?/farms/:farmId?/equipment/new" exact component={Equipment} />
                      <Route path="/businesses/:businessId?/farms/:farmId?/equipment/edit/:equipmentId?" exact component={Equipment} />
                      <Route path="/businesses/:businessId?/farms/:farmId?/meter/new" exact component={Meter} />
                      <Route path="/businesses/:businessId?/farms/:farmId?/meter/edit/:meterId?" exact component={Meter} />
                      <Route path="/businesses/:businessId?/farms/:farmId?/energybills/new" exact component={EnergyBill} />
                      <Route path="/businesses/:businessId?/farms/:farmId?/energybills/edit/:energyBillId?" exact component={EnergyBill} />
                      <Route path="/reconciliation" exact component={Reconciliation} />
                      <Route path="/opportunities" exact component={Opportunities} />
                      <Route path="/reports/dashboard" exact component={Report} />
                      <Route path="/prioritisation" exact component={Prioritisation} />
                      <Route path="/finalreport" exact component={FinalReport} />

                      <Route path="/stepper" exact component={Stepper} />                      
                    </>
                    }
                  </motion.div>
                </Switch>
              </LeftSidebar>
            </Route>
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default Routes;