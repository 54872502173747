import { MsalProvider } from "@azure/msal-react"; // , AuthenticatedTemplate, UnauthenticatedTemplate, useMsal
import CssBaseline from '@material-ui/core/CssBaseline';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, useHistory } from 'react-router-dom';
import './assets/base.scss';
import ErrorBoundary from './errorBoundary';
import { loadLocalState } from './localStateLoader';
import { initialAppDataState } from './reducers/appData';
import configureStore from './reducers/configureStore';
import Routes from './routes';
import { CustomNavigationClient } from "./utils/NavigationClient";
import ScrollToTop from './utils/ScrollToTop';
import { DndProvider } from 'react-dnd';
	import { HTML5Backend } from 'react-dnd-html5-backend';

let localState = loadLocalState();
localState = localState ? localState : initialAppDataState.data; 
const store = configureStore(localState);

function App ({ pca }) {
  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>      
      <BrowserRouter basename="/">
      <ErrorBoundary>
        <Provider store={store}>
          <DndProvider backend={HTML5Backend}>
            <CssBaseline />
            <ScrollToTop>
              <Routes />
            </ScrollToTop>
          </DndProvider>
        </Provider>
        </ErrorBoundary>
      </BrowserRouter>      
    </MsalProvider>
  );
}

export default App;

// store.subscribe(saveData);