import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { logger } from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension';
import reducers from '.';

export default function configureStore(initialState) {
  // initial state comes from localStorage and is just the appData .. 
  const stateFromLocalStorage = {
    appData: {
      data: {
        ...initialState
      }
    }
  };

  return createStore(
    combineReducers({
      ...reducers,
    }),
    stateFromLocalStorage,
    composeWithDevTools(
      applyMiddleware(thunk, logger),
    )
  ); 
}
