import React, { useState } from 'react';
import { connect } from 'react-redux';
import { TextField, MenuItem } from '@material-ui/core'; // Grid,
import { saveData } from '../../../reducers';
import { reportingParameters, selectReportingTimeframeUnits, selectReportingEnergyUnits, } from '../../../reducers/appData';
import FormControl from '@material-ui/core/FormControl';
import { timeframeUnits, energyUnits } from '../../../reducers/constants';

const ReportingParametersComponent = (props) => {  
  const {reportingTimeframeUnitId, reportingEnergyUnitId, dispatch} = props;
  const [formValues, setFormValues] = useState({reportTimeframeUnitId: reportingTimeframeUnitId, reportEnergyUnitId: reportingEnergyUnitId});

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setFormValues({
      ...formValues,
      [name]: value,
    });

    dispatch(saveData(reportingParameters, {...formValues, [name]: value})); 
  };

  return (
    <>
      {/* <Grid container spacing={4}>
        <Grid item xs={6} lg={6}>
          <div className="justify-content-center"> */}
            <FormControl component="fieldset">
              <TextField
                label="Timeframe"
                helperText="Please select the Reporting Timeframe"
                select
                className="m-4"
                id="timeframe-units-input"
                name="reportTimeframeUnitId"
                value={formValues.reportTimeframeUnitId}
                onChange={handleChange}
                variant="outlined"
              >
                {timeframeUnits.filter(option => option.enabled).sort((a, b) => a.orderId - b.orderId).map(option =>
                  <MenuItem key={option.id} value={option.id}>
                    {option.timeframeUnit}
                  </MenuItem>
                )}
              </TextField>
            </FormControl>
          {/* </div>
        </Grid>
        <Grid item xs={6} lg={6}> 
          <div className="justify-content-center">*/}
            <FormControl component="fieldset">
              <TextField
                label="Energy Units"
                helperText="Please select the Reporting Energy Units"
                select
                className="m-4"
                id="energy-units-input"
                name="reportEnergyUnitId"
                value={formValues.reportEnergyUnitId}
                onChange={handleChange}
                variant="outlined"
              >
                {energyUnits.filter(option => option.enabled).sort((a, b) => a.orderId - b.orderId).map(option =>
                  <MenuItem key={option.id} value={option.id}>
                    {option.energyUnit} - {option.description}
                  </MenuItem>
                )}
              </TextField>     
            </FormControl>
          {/* </div>
        </Grid>
      </Grid>   */}
    </>
  );
}

const mapStateToProps = (state) => {
  return {  
    reportingTimeframeUnitId: selectReportingTimeframeUnits(state),
    reportingEnergyUnitId: selectReportingEnergyUnits(state),
  }  
};

export default connect(mapStateToProps)(ReportingParametersComponent); 