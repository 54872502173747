
export const stepsStatuses = {
  NotStarted: 'NotStarted',
  InProgress: 'InProgress',
  Completed: 'Completed',
};

export const stepsDefaultValues = ([
  {
    id: 1,
    step: 'Instructions',
    link: '/instructions',
    status: 'NotStarted'
  }, 
  {
    id: 2,
    step: 'Energy Sources',
    link: '/businesses',
    status: 'NotStarted'
  },   
  {
    id: 3,
    step: 'Reconciliation',
    link: '/reconciliation',
    status: 'NotStarted'
  }, 
  {
    id: 4,
    step: 'Reports',
    link: '/reports/dashboard',
    status: 'NotStarted'
  }, 
  {
    id: 5,
    step: 'Opportunities',
    link: '/opportunities',
    status: 'NotStarted'
  }, 
  {
    id: 6,
    step: 'Prioritisation',
    link: '/prioritisation',
    status: 'NotStarted'
  },
  {
    id: 7,
    step: 'Final Report',
    link: '/finalreport',
    status: 'NotStarted'
  }
]); 

export const farmTypes = [
  {
    id: 1,
    value: 'grains',
    farmType: 'Grains',
    enabled: true,
    orderId: 1
  },
  {
    id: 2,
    value: 'cotton',
    farmType: 'Cotton',
    enabled: true,
    orderId: 2
  },
  {
    id: 3,
    value: 'milk',
    farmType: 'Milk',
    enabled: true,
    orderId: 3
  },
  {
    id: 4,
    value: 'wool',
    farmType: 'Wool',
    enabled: true,
    orderId: 4
  },
  {
    id: 5,
    value: 'fodderHayProduction',
    farmType: 'Fodder/Hay production',
    enabled: true,
    orderId: 5
  },
  {
    id: 6,
    value: 'silageWetWeight',
    farmType: 'Silage - wet weight',
    enabled: true,
    orderId: 6
  },
  {
    id: 7,
    value: 'turf',
    farmType: 'Turf',
    enabled: true,
    orderId: 7
  },
  {
    id: 8,
    value: 'cattleBreedingFinishing',
    farmType: 'Cattle - Steers/heifers/cows/bulls for breeding or finishing',
    enabled: true,
    orderId: 8
  },
  {
    id: 9,
    value: 'cattleSlaughterLiveWeight',
    farmType: 'Cattle - for slaughter (Live weight)',
    enabled: true,
    orderId: 9
  },
  {
    id: 10,
    value: 'cattleSlaughterDressedWeight',
    farmType: 'Cattle - for slaughter (Dressed Carcase weight)',
    enabled: true,
    orderId: 10
  },
  {
    id: 11,
    value: 'sheepBreedingFinishing',
    farmType: 'Sheep - Lambs/ewes/weathers/rams for breeding or finishing',
    enabled: true,
    orderId: 11
  },
  {
    id: 12,
    value: 'sheepSlaughterLiveWeight',
    farmType: 'Sheep - for slaughter (Live weight)',
    enabled: true,
    orderId: 12
  },
  {
    id: 13,
    value: 'sheepSlaughterDressedWeight',
    farmType: 'Sheep - for slaughter (Dressed Carcase weight)',
    enabled: true,
    orderId: 13
  },
  {
    id: 14,
    value: 'pigsProduction',
    farmType: 'Pigs - sows/boars for production',
    enabled: true,
    orderId: 14
  },
  {
    id: 15,
    value: 'pigsSlaughterLiveWeight',
    farmType: 'Pigs - Porkers/Baconers for slaughter (Live weight)',
    enabled: true,
    orderId: 15
  },
  {
    id: 15,
    value: 'pigsSlaughterDressedWeight',
    farmType: 'Pigs - Porkers/Baconers for slaughter (Dressed Carcase weight)',
    enabled: true,
    orderId: 15
  },
  {
    id: 16,
    value: 'poultrySlaughter',
    farmType: 'Poultry (Chicken/turkey/ducks) - for slaughter',
    enabled: true,
    orderId: 16
  },
  {
    id: 17,
    value: 'poultryEggs',
    farmType: 'Poultry - eggs',
    enabled: true,
    orderId: 17
  },
  {
    id: 18,
    value: 'wineGrapes',
    farmType: 'Wine grapes',
    enabled: true,
    orderId: 18
  },
  {
    id: 19,
    value: 'fruitsBoxed',
    farmType: 'Fruits - Boxed',
    enabled: true,
    orderId: 19
  },
  {
    id: 20,
    value: 'fruitsBulk',
    farmType: 'Fruits - Bulk',
    enabled: true,
    orderId: 20
  },
  {
    id: 21,
    value: 'vegetablesBoxed',
    farmType: 'Vegetables - Boxed',
    enabled: true,
    orderId: 21
  },
  {
    id: 22,
    value: 'vegetablesBulk',
    farmType: 'Vegetables - Bulk',
    enabled: true,
    orderId: 22
  }
];

export const farmProductionUnits = [
  {
    id: 1,
    value: 'tonnes',
    farmProductionUnit: 'Tonnes',
    enabled: true,
    orderId: 1
  },
  {
    id: 2,
    value: 'litres',
    farmProductionUnit: 'Litres',
    enabled: true,
    orderId: 2
  },
  {
    id: 3,
    value: 'bales',
    farmProductionUnit: 'Bales',
    enabled: true,
    orderId: 3
  },
  {
    id: 4,
    value: 'kilograms',
    farmProductionUnit: 'Kgs',
    enabled: true,
    orderId: 4
  },
  {
    id: 5,
    value: 'metresSquared',
    farmProductionUnit: 'Metres squared',
    enabled: true,
    orderId: 5
  },
  {
    id: 6,
    value: 'head',
    farmProductionUnit: 'Head',
    enabled: true,
    orderId: 6
  },
  {
    id: 7,
    value: 'dozen',
    farmProductionUnit: 'Dozen',
    enabled: true,
    orderId: 7
  },
  {
    id: 8,
    value: 'kgsTonnes',
    farmProductionUnit: 'Kgs/Tonnes',
    enabled: true,
    orderId: 8
  },
  {
    id: 9,
    value: 'caseOrCarton',
    farmProductionUnit: 'Case or Carton',
    enabled: true,
    orderId: 9
  },
  {
    id: 10,
    value: 'other',
    farmProductionUnit: 'Please nominate a Unit of Production',
    enabled: true,
    orderId: 10
  }
]; 

export const equipmentTypes = [
  {
    id: 1,
    value: 'tractors',
    equipmentType: 'Tractors',
    enabled: true,
    orderId: 1
  },
  {
    id: 2,
    value: 'motorsPumpsDrives',
    equipmentType: 'Motors, pumps and drives',
    enabled: true,
    orderId: 2
  },
  {
    id: 3,
    value: 'refrigerationCommercial',
    equipmentType: 'Refrigeration (Commercial)',
    enabled: true,
    orderId: 3
  },
  {
    id: 4,
    value: 'refrigerationIndustrial',
    equipmentType: 'Refrigeration (Industrial)',
    enabled: true,
    orderId: 4
  },
  {
    id: 5,
    value: 'CompressedAir',
    equipmentType: 'Compressed air',
    enabled: true,
    orderId: 5
  },
  {
    id: 6,
    value: 'hotWaterHeatDistrubition',
    equipmentType: 'Hot water and heat distribution',
    enabled: true,
    orderId: 6
  },
  {
    id: 7,
    value: 'climateControl',
    equipmentType: 'Climate Control (HVAC)',
    enabled: true,
    orderId: 7
  },
  {
    id: 8,
    value: 'lighting',
    equipmentType: 'Lighting',
    enabled: true,
    orderId: 8
  },
  {
    id: 9,
    value: 'dryers',
    equipmentType: 'Dryers',
    enabled: true,
    orderId: 9
  }  
]; 

export const practices = [
  {
    id: 1,
    equipmentTypeId: 1,
    name: 'QW: Adaptive driving',
    value: 'adaptiveDriving',
    description:'Modern Tractors are equipped with advanced telemetry such as Tractor Management Systems (TMS) which enable the farmer to assess the actual fuel efficiency of Tractors as it is being used. Strategies to adapt operating techniques are typically devised based on the assessed performance (i.e. performance reports from the TMS). Key energy saving measures are reducing the time spent idling and ensure the correct use of gears. With reference to gearing, for maximum operating efficiency, an engine should be operated near its rated capacity. However, a number of field operations such as light tillage, planting, cultivating, spraying, and hay raking do not require full tractor power. Lowering the engine speed by shifting to a higher gear can save 10 – 15% of fuel depending on tractor and load conditions. The TMS also allows the driver to monitor transmission and engine functions, monitor and adjust hydraulic flow settings and collect data for future use.',
    enabled: true,
    orderId: 1,
    percentageSaving: 13,
    paybackYears: 0.5,
    lifetimeYears: 1
  },
  {
    id: 2,
    equipmentTypeId: 1,
    name: 'QW: Ballasting',
    value: 'ballasting',
    description: 'Maximise traction, and therefore fuel efficiency, by properly ballasting tractors for the operation at hand. Ballasting a tractor adds weight to the rear for increased traction and/or to the front for stability and better steering. Tractive efficiency is a measure of the effectiveness of the tractor in transferring available power to the ground and is directly affected by weight distribution and tyre slip.',
    enabled: true,
    orderId: 2,
    percentageSaving: 7,
    paybackYears: 0.5,
    lifetimeYears: 1
  },
  {
    id: 3,
    equipmentTypeId: 1,
    name: 'QW: Vehicle maintenance',
    value: 'vehicleMaintenance',
    description: 'A simple way to save fuel and money on the farm is to keep farm vehicles and equipment in good operating condition. This will help to reduce repair costs and improve reliability.',
    enabled: true,
    orderId: 3,
    percentageSaving: 8,
    paybackYears: 0.5,
    lifetimeYears: 1
  },
  {
    id: 4,
    equipmentTypeId: 1,
    name: 'QW: Tyre pressure',
    value: 'tyrePressure',
    description: 'Fuel consumption is very sensitive to tyre pressure. When driving directly on the road, the tyre pressure should be as high as possible in order to minimise the road resistance. This applies to tractors as well as for heavy transport vehicles. If the tyre pressure for driving on the road is increased from the lowest value to the maximum permissible value, a fuel reduction of about 15% can be estimated. Lower wheel pressures lead to a lower track depth of the tractor in the field. The working depth and the corresponding fuel  consumption can be reduced for the tilling equipment. Tyre pressures should be adjusted to take account of the axle load, driving surface and speed. Use a low tyre pressure in the field to reduce the tractive power demand, as well as the track depth and soil compaction. Soil compactions increase the power required and therefore the fuel consumption. By contrast, higher tyre pressures on the road will reduce rolling resistance.',
    enabled: true,
    orderId: 4,
    percentageSaving: 4,
    paybackYears: 0.05,
    lifetimeYears: 1
  },
  {
    id: 5,
    equipmentTypeId: 1,
    name: 'Tyre pressure (CTI)',
    value: 'tyrePressure',
    description: 'Fuel consumption is very sensitive to tyre pressure. To alleviate compaction, a Central Tyre Inflation System (CTI or hydraulic inflation system) allows quick adjustment of tyre pressure for the field and the road.',
    enabled: true,
    orderId: 5,
    percentageSaving: 5,
    paybackYears: 0.08,
    lifetimeYears: 5
  },
  {
    id: 6,
    equipmentTypeId: 1,
    name: 'QW: Equipment correctly set',
    value: 'tyrePressure',
    description: 'For many devices, such as ploughs, a wrongly adjusted traction point may lead to an increased traction demand of approximately 19%. If this goes along with a wrong inclination (plough gradient), the traction demand will rise by 33% in comparison to an optimal adjusting of the equipment. Consequently, this could have a significant influence on the power requirement and therefore the fuel consumption.',
    enabled: true,
    orderId: 6,
    percentageSaving: 7,
    paybackYears: 0.5,
    lifetimeYears: 1
  },
  {
    id: 7,
    equipmentTypeId: 2,
    name: 'VSD on pumps',
    value: 'vsdPumps',
    description: 'The installation of VSDs on pumps is an important energy saving measure as lowering the speed of a motor by just 20% can produce an energy saving of up to 50%. It covers all pumps, including pumps associated with the HVAC systems. The VSD needs to be connected to some control signal and may also require installation of measurement devices or controllers which typically should be included in this costing of the measure. The financial viability of installing a VSD depends on the motor application and operating hours. VSDs tend to be most economical on large pumps.',
    enabled: true,
    orderId: 1,
    percentageSaving: 20,
    paybackYears: 2.5,
    lifetimeYears: 15
  },
  {
    id: 8,
    equipmentTypeId: 2,
    name: 'VSD on fans',
    value: 'vsdFans',
    description: 'By installing VSDs on fans, the speed of the fan motors can be controlled to match the amount of air/gas needed to be moved throughout your building/process and therefore reduce energy use and operation costs.',
    enabled: true,
    orderId: 2,
    percentageSaving: 5,
    paybackYears: 2.5,
    lifetimeYears: 15
  },
  {
    id: 9,
    equipmentTypeId: 2,
    name: 'High efficiency motors',
    value: 'highEfficiencyMotors',
    description: 'Electric motors account for a significant portion of a farm\'s energy usage. Larger motors are used in refrigeration. The smaller motors on sites operate hydraulic equipment, fans and pumps. HEMs are between 3% and 4% more efficient than motors meeting the prescribed minimum energy performance standard (MEPS). Energy efficiency improvements of up to 5% are possible if failed motors are replaced with higher efficiency models. It is usually more cost-effective to replace smaller motors (<15kW) than to rewind them. (See separate measures for pump motors)', 
    enabled: true,
    orderId: 3,
    percentageSaving: 5,
    paybackYears: 2.5,
    lifetimeYears: 15
  },
  {
    id: 10,
    equipmentTypeId: 3,
    name: 'Upgrade to a HE compressor',
    value: 'heCompressor',
    description: 'Typically compressors come as a single, sealed unit. So the compressor and motor which drives it needs to be replaced at the same time. These are “hermetically sealed” units. When the compressor or motor needs replacing, take the opportunity to install a new system as advances in both the motor and the compressors makes this a high value opportunity.',
    enabled: true,
    orderId: 1,
    percentageSaving: 7,
    paybackYears: 0.74,
    lifetimeYears: 10
  },
  {
    id: 11,
    equipmentTypeId: 3,
    name: 'VSD to compressor',
    value: 'vsdCompressor',
    description: 'Variable speed drives (VSD) adjust the speed of an electric motor that uses AC power, making low speed operation possible and efficient. The benefits of VSDs are two-fold. Allowing low-speed operation means the compressor can be run closer to its best-efficiency-point (BEP), which in turn reduces the number of compressor on-off cycles, reducing current spikes. This reduces energy cost and can also improve compressor life. VSDs are not compatible with all motors and are generally bought as a motor and VSD pair. Energy savings associated with VSDs are linked to the reduction of refrigerant mass flow. Lower refrigerant flow reduces the condensing pressure and in turn, compressor energy consumption. VSDs draw some power, so will not always yield energy savings. As a general rule, if the compressor works at a full load less than 80% of the time, the addition of a VSD will save energy.',
    enabled: true,
    orderId: 2,
    percentageSaving: 14,
    paybackYears: 1.54,
    lifetimeYears: 10
  },
  {
    id: 12,
    equipmentTypeId: 4,
    name: 'Variable head pressure control',
    value: 'variableHeadPressureControl',
    description: 'Variable plant pressure control is a strategy which aims to improve a plant\'s energy efficiency by optimising the head pressure of the refrigeration plant, based on instantaneous plant load and ambient conditions.',
    enabled: true,
    orderId: 1,
    percentageSaving: 8,
    paybackYears: 3,
    lifetimeYears: 10
  },
  {
    id: 13,
    equipmentTypeId: 4,
    name: 'Upgrade compressors',
    value: 'upgradeCompressors',
    description: 'Compressor degradation is one of the major causes of output and efficiency loss. Degradation reduces the flow and pressure ratio, in turn increasing the power needed to maintain the plant\'s refrigeration load requirements. Fouling, erosion, corrosion, abrasion and damage are major contributors of degradation.',
    enabled: true,
    orderId: 2,
    percentageSaving: 25,
    paybackYears: 4,
    lifetimeYears: 10
  },
  {
    id: 14,
    equipmentTypeId: 4,
    name: 'Insulation on tanks',
    value: 'insulationOnTanks',
    description: 'Heat loss from tanks can be limited by applying one of the three main types of tank insulation: • spray-on insulation (used for large applications) • foil over bubble wrap • rigid foam with an outer shell',
    enabled: true,
    orderId: 3,
    percentageSaving: 25,
    paybackYears: 2.5,
    lifetimeYears: 25
  },
  {
    id: 15,
    equipmentTypeId: 4,
    name: 'Increase temperature 1 degree',
    value: 'insulationOnTanks',
    description: 'Cold stores generally run to a fixed set-point room temperature throughout the day. This causes the refrigeration plant load to increase during peak periods to maintain temperature, and to run largely unloaded during off-peak periods. For products that require cold storage and can tolerate slight variations in storage temperature, such as meat products and frozen vegetables, variable cold store temperatures can exploit off-peak efficiencies, lower off-peak power costs and minimise peak demand costs. Increasing frozen food store temperature from -25ºC to -24ºC saves 2-4% of the refrigeration compressor energy.',
    enabled: true,
    orderId: 4,
    percentageSaving: 3,
    paybackYears: 0.05,
    lifetimeYears: 10
  },
  {
    id: 16,
    equipmentTypeId: 4,
    name: 'Variable evaporator fan speed',
    value: 'insulationOnTanks',
    description: 'A multi-speed motor or fan is a potential lower-cost alternative to a VSD. Beyond cost considerations it may also be more effective if the cooling load is more constant. Multi-speed motors use a different set of windings at each speed to increase fan efficiency. Although single-speed motors are more efficient at their single optimal speed, multi-speed motors have many speeds at which they are very efficient.',
    enabled: true,
    orderId: 5,
    percentageSaving: 2,
    paybackYears: 4,
    lifetimeYears: 10
  },
  {
    id: 17,
    equipmentTypeId: 5,
    name: 'Minimise pressure drop on pipes',
    value: 'minimisePressureDropOnPipes',
    description: 'An air compressor needs to generate airflow at a rate and pressure that meets the demands of the end-use and overcomes the friction and gravitational flow losses (pressure drop) in the pipe/hose network. Pressure drops can be reduced by: • increasing the pipe/hose diameter to reduce friction, and therefore, help to reduce flow losses. Pipe friction is inversely proportional to the fifth power of internal pipe diameter for circular pipes • selecting rigid pipes made of smooth material will cause less friction and therefore transfer air more efficiently • minimising the length of pipe and components that increase joins (i.e. less smooth areas) and the likelihood of bends.',
    enabled: true,
    orderId: 1,
    percentageSaving: 3,
    paybackYears: 2,
    lifetimeYears: 40
  },
  {
    id: 18,
    equipmentTypeId: 5,
    name: 'Compressor maintenance',
    value: 'compressorMaintenance',
    description: 'Leakage accounts for nearly one third of compressed air energy consumption. A proactive leak repair and maintenance program should be followed for the compressed air system. Applying this measure in a sustainable manner requires: • regular inspection of compressed air equipment, air pipes, bends and valves • an assessment of all air channels to ensure proper physical supports to prevent leaks through excess stress • disconnection or isolation of any unused parts of the air distribution network or unused pressure regulators • engaging with plant floor staff who are most likely to notice changes in system performance. Leak tag processes can be complemented by the use of an ultrasonic compressed air leak detection device that accurately identifies leaks by detecting the noise frequency caused by air turbulence.',
    enabled: true,
    orderId: 2,
    percentageSaving: 35,
    paybackYears: 0.75,
    lifetimeYears: 5
  },
  {
    id: 19,
    equipmentTypeId: 6,
    name: 'Solar hot water',
    value: 'solarHotWater',
    description: 'Solar hot water works well in buildings that have significant roof area to locate the solar collectors. A solar hot water system should provide between 50 and 90 per cent of your hot water needs. Solar hot water systems come with electric or gas boosters to provide the rest of your hot water needs. Solar hot water systems use solar collectors (either solar panels or evacuated tubes) which absorb energy from the sun to heat water. The heated water is then stored in an insulated tank for when you need it. If you are located in in a frost-prone area, ask your installer about frost protection for your solar hot water system.',
    enabled: true,
    orderId: 1,
    percentageSaving: 60,
    paybackYears: 6.50,
    lifetimeYears: 25
  },
  {
    id: 20,
    equipmentTypeId: 6,
    name: 'Heat pump hot water',
    value: 'heatPumpHotWater',
    description: 'Unlike a conventional electric storage water heater which uses electricity to heat water directly, heat pumps use electricity only to operate a pump that circulates a refrigerant around the system. This refrigerant picks up heat from the air and transfers it to the water. They are approximately three times more efficient than a conventional electric storage water heater. Although air-sourced heat pumps are most common, other heat pump types are available that extract heat from water or the ground.',
    enabled: true,
    orderId: 2,
    percentageSaving: 60,
    paybackYears: 5,
    lifetimeYears: 15
  },
  {
    id: 21,
    equipmentTypeId: 6,
    name: 'Pipe sizing, routing and leakage',
    value: 'pipeSizing',
    description: 'Hot water pumps usually need to generate fluid flow at a rate and pressure that meets the demands of the end-use, as well as overcome any gravitational flow and friction losses. Consequently, in addition to eliminating leakage, energy use can be reduced by: • ensuring the internal surface of the pipe is smooth • reducing pipe length as pipe friction increases with increasing length • reducing bends and other restrictions caused by added components. Even a fully open valve can add 70 kpa of pressure drop • increasing the elevation of the pump.',
    enabled: true,
    orderId: 3,
    percentageSaving: 8,
    paybackYears: 1.25,
    lifetimeYears: 5
  },
  {
    id: 22,
    equipmentTypeId: 6,
    name: 'Insulation on pipes and boilers',
    value: 'insulationPipesBoilers',
    description: 'Insulation can be a cost effective solution for any surface that heats above 50°C.  Insulation on hot water lines can  provide a two year payback on investment.',
    enabled: true,
    orderId: 4,
    percentageSaving: 8,
    paybackYears: 3.50,
    lifetimeYears: 30
  },
  {
    id: 23,
    equipmentTypeId: 7,
    name: 'HVAC controls',
    value: 'hvacControls',
    description: 'Smart heating and cooling controls strategies can be implemented using a range of technologies, from basic timers to software-enabled thermostat control systems for the system to respond to prevailing "climate conditions". Available options will depend on the type of facility. Simple timers are a good option for wall mounted air conditioners. For farm facilities with more advanced split systems, programmed controls should be considered. Also consider adjusting the temperature set point as each one degree increase in the temperature setting decreases electricity use by about 3%.',
    enabled: true,
    orderId: 1,
    percentageSaving: 10,
    paybackYears: 0.25,
    lifetimeYears: 15
  },
  {
    id: 24,
    equipmentTypeId: 7,
    name: 'Variable evaporator fan speeds',
    value: 'variableEvaporatorFanSpeeds',
    description: 'A multi-speed motor or fan is a potential lower-cost alternative to a Variable Speed Drive (VSD). Beyond cost considerations it may also be more effective if the cooling load is more constant. Multi-speed motors use a different set of windings at each speed to increase fan efficiency. Although single-speed motors are more efficient at their single optimal speed, multi-speed motors have many speeds at which they are very efficient.',
    enabled: true,
    orderId: 2,
    percentageSaving: 2,
    paybackYears: 4,
    lifetimeYears: 10
  },
  {
    id: 25,
    equipmentTypeId: 7,
    name: 'Ceiling insulation / envelope',
    value: 'ceilingInsulation',
    description: 'A collection of measures related to insulating the building shell. These can include insulation in the ceiling or in the wall cavity. Insulation must comply with the performance requirements of AS/NZS 4859.1:2002 (insulation material) and achieve minimum summer R-value specified when measured and labelled in accordance with AS/NZS 4859.1:2002. The Building Code of Australia (BCA 2010 Volume Two Part 3.12) sets out minimum requirements for the R-values of materials used in construction of buildings. AS/NZS 4200.1:1994 also provide standards for materials used as pliable building membranes and underlays.',
    enabled: true,
    orderId: 3,
    percentageSaving: 2,
    paybackYears: 3,
    lifetimeYears: 25
  },
  {
    id: 26,
    equipmentTypeId: 7,
    name: 'Reflective roof coating',
    value: 'reflectiveRoofCoating',
    description: 'Reflective roof coatings act like mirrors, reflecting the sun\'s heat back into space. They can substantially reduce the temperature inside buildings. This reduces the cooling load/electrical load for air conditioning. The benefits have been modelled assuming limited or no ceiling insulation. Pre-existing ceiling insulation will reduce the benefits of this measure. Surface coatings must comply with the performance requirements of AS/NZS 4859.1:2002 (Section C5 Thermal Properties of Surface Coatings of the standard requires surface coatings, described as "insulation" to have a thermal resistance of above 0.02 m2K/W).',
    enabled: true,
    orderId: 4,
    percentageSaving: 2,
    paybackYears: 3,
    lifetimeYears: 5
  },
  {
    id: 27,
    equipmentTypeId: 8,
    name: 'Voltage reduction',
    value: 'voltageReduction',
    description: 'Voltage reduction and voltage optimisation are particularly suitable to older sites where major lighting infrastructure changes may be difficult. Reducing voltage will reduce the amount of power drawn and decrease energy use. The larger voltage optimisation systems can also provide improved power quality which can have a beneficial effect on the electrical infrastructure on site. However, it is important to note that electronic ballasts in a circuit will compensate for the voltage reduction and negate any savings. Therefore voltage reduction is not suitable for use on luminaires with electronic ballasts. The presence of electronic equipment will also reduce the effectiveness of Voltage Power Optimisation (VPO). Light levels will also be lower when voltage reduction devices are used. It is therefore important to ensure light output remains compliant with AS/NZS1680 standards.',
    enabled: true,
    orderId: 1,
    percentageSaving: 14,
    paybackYears: 2,
    lifetimeYears: 10
  },
  {
    id: 28,
    equipmentTypeId: 8,
    name: 'Lighting controls',
    value: 'lightingControls',
    description: 'This cluster of energy efficiency measures covers a range of activities ranging from the installation of a single occupancy sensor through to using complex whole-of-building systems covering photoelectric and motion sensors. Ultimately savings will depend on site-specific features and the control strategy selected. The ability to effectively control building lighting depends on three major factors: - lamp technologies that can be turned on, off and dimmed - sensors that can detect building use and environmental conditions - control systems that vary light output according to sensor inputs and user needs across different locations. Lighting control decisions can be made based on a large range of factors, including time of day, activity, light level, temperature or a combination of the above.',
    enabled: true,
    orderId: 2,
    percentageSaving: 13,
    paybackYears: 2,
    lifetimeYears: 8
  },
  {
    id: 29,
    equipmentTypeId: 8,
    name: 'Efficient lighting',
    value: 'efficientLighting',
    description: 'The measure covers all instances where one lighting technology is replaced with another. It could also cover upgrades to fittings as well as the lamps. Most lights likely to be replaced are currently not covered by Minimum Energy Performance Standards (MEPS). It is most common for incandescent/metal halide lights to be replaced by a CFL or LED, with LEDs becoming viable for an increasingly wide range of options. Also the older type of T8 fluorescent tubes often used in factories and high-bay warehouse applications are being replaced with newer generation fitting using triphosphoric T8 tubes and electronic ballasts or highly efficient T5 fluorescent fittings.',
    enabled: true,
    orderId: 3,
    percentageSaving: 30,
    paybackYears: 3,
    lifetimeYears: 13
  },
  {
    id: 30,
    equipmentTypeId: 8,
    name: 'Reflectors / delamping',
    value: 'reflectorsDelamping',
    description: 'Many work places are “over exposed”. Removing one fluorescent tube from a bank of two could be a practical way of reducing consumption. To optimise savings, the ballasts associated with the lamps taken out must be removed by an electrician. Installing reflectors offers an alternative to increasing the number of lights when more light is required in an area. From an occupational health and safety perspective, businesses should ensure that the site, after reducing light output, still meets minimum luminance levels recommended by relevant lighting standards (AS/NZS1680). The output from lamps can be enhanced mechanically by fitting reflectors that meet AS/NZS1680 standards.',
    enabled: true,
    orderId: 4,
    percentageSaving: 10,
    paybackYears: 0.75,
    lifetimeYears: 17
  },
  {
    id: 31,
    equipmentTypeId: 9,
    name: 'Controls on dryers',
    value: 'controlsOnDryers',
    description: 'Automatic controllers such as feedback controllers, can help to minimise dryer energy consumption by more precisely controlling energy inputs to meet the needs of the product being processed. Common sensors used in the drying process control include thermocouples and resistance thermometers (for air temperature), infrared pyrometers (for product surface temperatures), and wet-bulb and dry-bulb thermometers, resistance sensors, and absorption capacitive sensors (for air humidity).',
    enabled: true,
    orderId: 1,
    percentageSaving: 7,
    paybackYears: 2.5,
    lifetimeYears: 10
  },
  {
    id: 32,
    equipmentTypeId: 9,
    name: 'Dryers - heat recovery',
    value: 'dryersHeatRecovery',
    description: 'Heat can be recovered in retrofit applications by utilising the exhaust air of a dryer to preheat the inlet air stream, thereby saving energy. The success of this measure depends on the available space for additional duct work near the dryer. Either the exhaust air can be directly injected into the inlet air stream, or a heat exchanger system can be employed to indirectly heat the inlet air stream using exhaust air. Savings are sensitive to dryer operating temperature and factory fitted heat recovery capability.',
    enabled: true,
    orderId: 2,
    percentageSaving: 10,
    paybackYears: 3,
    lifetimeYears: 12
  },
];

export const fuelTypes = [
  {
    id: 1,
    value: 'diesel',
    fuelType: 'Diesel',
    enabled: true,
    orderId: 1,
    defaultMeasurementUnitId: 4,
    gJFactor: 0.01188,
    defaultCurrencyTypeId: 1
  },
  {
    id: 2,
    value: 'petrol',
    fuelType: 'Petrol',
    enabled: true,
    orderId: 2,
    defaultMeasurementUnitId: 4,
    gJFactor: 0.0342,
    defaultCurrencyTypeId: 1
  },
  {
    id: 3,
    value: 'lpg',
    fuelType: 'LPG',
    enabled: true,
    orderId: 3,
    defaultMeasurementUnitId: 4,
    gJFactor: 0.0257,
    defaultCurrencyTypeId: 2
  },
  {
    id: 4,
    value: 'naturalGas',
    fuelType: 'Natural gas',
    enabled: true,
    orderId: 4,
    defaultMeasurementUnitId: 2,
    gJFactor: 1,
    defaultCurrencyTypeId: 2
  },
  {
    id: 5,
    value: 'electricity',
    fuelType: 'Electricity',
    enabled: true,
    orderId: 5,
    defaultMeasurementUnitId: 1,
    gJFactor: 0.0036,
    defaultCurrencyTypeId: 2
  }
]

export const measurementUnits = [
  {
    id: 1,
    value: 'kWh',
    measurementUnit: 'kWh',
    longMeasurementUnit: 'Kilowatt hours',
    enabled: true,
    orderId: 1
  },
  {
    id: 2,
    value: 'gJ',
    measurementUnit: 'GJ',
    longMeasurementUnit: 'Gigajoules',
    enabled: true,
    orderId: 2
  },
  {
    id: 4,
    value: 'l',
    measurementUnit: 'L',
    longMeasurementUnit: 'Litres',
    enabled: true,
    orderId: 4
  },
]

export const timeframeUnits = [
  {
    id: 1,
    value: 'day',
    timeframeUnit: 'Day',
    enabled: true,
    orderId: 1,
    multiplier: 1,
    annualMultiplier: 365
  },
  {
    id: 2,
    value: 'week',
    timeframeUnit: 'Week',
    enabled: true,
    orderId: 2,
    multiplier: 7,
    annualMultiplier: 52
  },
  {
    id: 3,
    value: 'month',
    timeframeUnit: 'Month',
    enabled: true,
    orderId: 3,
    multiplier: 30,
    annualMultiplier: 12
  },
  {
    id: 4,
    value: 'year',
    timeframeUnit: 'Year',
    enabled: true,
    orderId: 4, 
    multiplier: 365,
    annualMultiplier: 1
  },
]

export const energyUnits = [
  {
    id: 1,
    value: 'gj',
    energyUnit: 'GJ',
    description: 'Gigajoules',
    enabled: true,
    orderId: 1,
    gjMultiplier: 1
  },
  {
    id: 2,
    value: 'mj',
    energyUnit: 'MJ',
    description: 'Megajoules',
    enabled: true,
    orderId: 2,
    gjMultiplier: 1000
  },
  {
    id: 3,
    value: 'kj',
    energyUnit: 'kJ',
    description: 'Kilojoules',
    enabled: true,
    orderId: 3,
    gjMultiplier: 1000000
  },
  {
    id: 4,
    value: 'kwh',
    energyUnit: 'kWh',
    description: 'Kilowatt hours',
    enabled: true,
    orderId: 4,
    gjMultiplier: 277.78
  },
  {
    id: 5,
    value: 'th',
    energyUnit: 'th',
    description: 'Thermies',
    enabled: true,
    orderId: 5,
    gjMultiplier: 238.85
  },
  {
    id: 6,
    value: 'kcal',
    energyUnit: 'kCal',
    description: 'Kilocalories',
    enabled: true,
    orderId: 6,
    gjMultiplier:  238845.9
  },
  {
    id: 7,
    value: 'wsecs',
    energyUnit: 'WSecs',
    description: 'Watt seconds',
    enabled: true,
    orderId: 7,
    gjMultiplier:  1000000000
  },
  {
    id: 8,
    value: 'btu',
    energyUnit: 'BTUs',
    description: 'British Thermal units',
    enabled: true,
    orderId: 8,
    gjMultiplier:  947817.07775
  }
]

export const chargeUnits = [
  {
    id: 1,
    value: 'c/kWh',
    timeframeUnit: 'Cents / kilo Watt Hours',
    enabled: true,
    orderId: 1
  }
]

export const energyBillTypes = [
  {
    id: 1,
    value: 'usage',
    label: 'Usage',
    enabled: true,
    orderId: 1
  },
  {
    id: 2,
    value: 'contribution',
    label: 'Contribution',
    enabled: true,
    orderId: 2
  }
]

export const currencyTypes = [
  {
    id: 1,
    value: 'dollars',
    label: 'Dollars',
    symbol: "$",
    enabled: true,
    orderId: 1
  },
  {
    id: 2,
    value: 'cents',
    label: 'Cents',
    symbol: 'c', 
    enabled: true,
    orderId: 2
  }
]