import axios from 'axios'; 
import { msalInstance } from "../index";
import { apiConfig } from "../authConfig";

export async function getDataFromApi() {
  // console.log('apiConfig: ', JSON.stringify(apiConfig));

  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
  }

  const response = await msalInstance.acquireTokenSilent({
      ...apiConfig,
      account: account
  });

  const authHeaders = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${response.accessToken}`
    }
  };

  try {
    const res = await axios.get(`${apiConfig.uri}/getData?homeAccountId=${account.homeAccountId}`, authHeaders);
    if (res.data) {
      return res.data;
    }
  } catch(exc) {
    return 'Error from api';
  }
}

export async function saveDataToApi(data) {
  const account = msalInstance.getActiveAccount();
  if (!account) {
      throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
  }
  const response = await msalInstance.acquireTokenSilent({
      ...apiConfig,
      account: account
  });

  const authHeaders = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${response.accessToken}`
    }
  };

  const postData = {
    "id": account.homeAccountId,
    "homeAccountId": account.homeAccountId,
    "email": account.username, 
    "name": account.name,
    ...data
  }
  // console.log(`about to call api ${apiConfig.uri}/postData with data: ${postData}`);

  axios.post(`${apiConfig.uri}/postData`, JSON.stringify(postData), authHeaders)
  .then((res) => {
      console.log(`API call Status: ${res.status}`);
      // console.log('Body: ', res.data);
  }).catch((err) => {
      console.error('Error calling POST method: ', err);
  }).finally(() => {
    console.log('Finally in POST api call.');
  });
  // console.log('Finished POST call to api');
}